<template>
  <div class="goods-list">
    <!----------------------------------------------- 商品增删改查STA ----------------------------------------------->
    <section class="goods-crud">
      <span class="option" @click="handleDelete">删除</span>
      <span class="vertical-divider"></span>
      <span class="option" @click="handleShangJia">上架</span>
      <span class="vertical-divider"></span>
      <span class="option" @click="handleXiaJia">下架</span>
      <span class="vertical-divider"></span>
      <span class="option" @click="handleCreate">新增</span>
    </section>
    <!----------------------------------------------- 商品增删改查END ----------------------------------------------->

    <!----------------------------------------------- 商品查询列表STA ----------------------------------------------->
    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%;"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="缩略图">
        <template slot-scope="scope">
          <img class="category-bg" :src="scope.row.url" />
        </template>
      </el-table-column>
      <el-table-column label="标题">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span>
            {{
              scope.row.state == 1
                ? '已上架'
                : scope.row.state == 2
                ? '已下架'
                : '已删除'
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="编辑">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">
            编辑
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="排序">
        <template slot-scope="scope">
          <el-button
            size="mini"
            @click="handleMoveUp(scope.$index, scope.row)"
            :disabled="scope.$index == 0"
          >
            上移
          </el-button>
          <el-button
            size="mini"
            @click="handleMoveDown(scope.$index, scope.row)"
            :disabled="scope.$index == tableData.length - 1"
          >
            下移
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!----------------------------------------------- 商品查询列表END ----------------------------------------------->
  </div>
</template>

<script>
import {
  reqGetAllSXGoodsByCategoryID,
  reqDeletesGoods,
  reqShangJiaGoods,
  reqXiaJiaGoods,
  reqMoveGoods,
} from '@/api'

export default {
  name: 'GoodsList',
  components: {},
  data() {
    return {
      categoryID: -1,
      tableData: [],
      multipleSelection: [],
      value: [],
      deleteDialogVisible: false,
    }
  },
  mounted() {
    this.categoryID = this.$route.query.id
    this.getGoods()
  },
  methods: {
    async getGoods() {
      const data = await reqGetAllSXGoodsByCategoryID(this.categoryID)
      this.tableData = data.data
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleDelete() {
      if (this.multipleSelection.length === 0) {
        return
      }
      this.$confirm('确认删除吗？')
        .then((_) => {
          reqDeletesGoods(this.multipleSelection)
            .then((res) => {
              this.tableData.forEach((v, k) => {
                this.multipleSelection.forEach((j) => {
                  if (j.id === v.id) {
                    this.tableData.splice(k, 1)
                  }
                })
              })
              this.$message.success(res.msg)
            })
            .catch((err) => {})
            .finally(() => {
              this.$refs.multipleTable.clearSelection()
            })
        })
        .catch((_) => {})
    },
    handleCreate() {
      this.$router.push({ path: '/goodsc', query: { id: this.categoryID } })
    },
    handleEdit(index, v) {
      this.$router.push({
        path: '/goodsc',
        query: { edit: 1, id: v.id },
      })
    },
    handleShangJia() {
      if (this.multipleSelection.length === 0) {
        return
      }
      this.$confirm('确认上架吗？')
        .then((_) => {
          reqShangJiaGoods(this.multipleSelection)
            .then((res) => {
              this.tableData.forEach((v, k) => {
                this.multipleSelection.forEach((j) => {
                  if (j.id === v.id) {
                    this.tableData[k].state = 1
                  }
                })
              })
              this.$message.success(res.msg)
            })
            .catch((err) => {})
            .finally(() => {
              this.$refs.multipleTable.clearSelection()
            })
        })
        .catch((_) => {})
    },
    handleXiaJia() {
      if (this.multipleSelection.length === 0) {
        return
      }
      this.$confirm('确认下架吗？')
        .then((_) => {
          reqXiaJiaGoods(this.multipleSelection)
            .then((res) => {
              this.tableData.forEach((v, k) => {
                this.multipleSelection.forEach((j) => {
                  if (j.id === v.id) {
                    this.tableData[k].state = 2
                  }
                })
              })
              this.$message.success(res.msg)
            })
            .catch((err) => {})
            .finally(() => {
              this.$refs.multipleTable.clearSelection()
            })
        })
        .catch((_) => {})
    },
    handleMoveUp(index, v) {
      //1.更新视图
      if (index == 0) {
        return
      }
      this.tableData.splice(
        index - 1,
        1,
        ...this.tableData.splice(index, 1, this.tableData[index - 1]),
      )
      //2.更新数据库
      reqMoveGoods([
        {
          id: this.tableData[index].id,
          orderNum: index,
        },
        {
          id: this.tableData[index - 1].id,
          orderNum: index - 1,
        },
      ])
        .then((res) => {
          this.$message.success(res.msg)
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    handleMoveDown(index, v) {
      //1.更新视图
      if (index == this.tableData.length - 1) {
        return
      }
      this.tableData.splice(
        index + 1,
        1,
        ...this.tableData.splice(index, 1, this.tableData[index + 1]),
      )
      //2.更新数据库
      reqMoveGoods([
        {
          id: this.tableData[index].id,
          orderNum: index,
        },
        {
          id: this.tableData[index + 1].id,
          orderNum: index + 1,
        },
      ])
        .then((res) => {
          this.$message.success(res.msg)
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
  },
}
</script>

<!--  -->
<style lang="scss">
.goods-list {
  width: 100%;
  height: 100%;
  background-color: $def-content-bg-color;
  border-radius: $def-radius;
  padding: $def-margin * 4;
  position: relative;

  .category-bg {
    width: 88px;
    height: 88px;
  }

  .el-tabs__item {
    height: 48px;
    font-size: 16px;
    font-weight: 400;
  }

  .el-tabs__header {
    margin: 0 0;
  }

  .goods-filter {
    margin-top: $def-margin * 3;
    .el-form {
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-gap: 20px;

      .el-form-item__label {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin-right: $def-margin;
        // font-size: 14px;
        text-overflow: ellipsis;

        word-break: break-all;
        white-space: nowrap;
      }
      .el-form-item {
        display: flex;
        margin: 0;
        .el-form-item__content {
          display: flex;
          width: 61%;
        }
      }
    }

    .price-filter {
      .el-form-item__content {
        width: 60%;
        align-items: center;
        span {
          width: 36px;
          height: 1px;
          margin: 0 $def-margin;
          background-color: $def-hr-bg-color;
        }
      }
    }

    .submit {
      display: flex;
    }
  }

  .goods-crud {
    padding: $def-margin * 3;
    height: 36px;
    background-color: #f4f4f4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: $def-radius;
    .option {
      font-size: 16px;
      color: $theme-color;
      cursor: pointer;
    }
  }

  .goods-query-list {
    .goods-query-list-header {
      display: flex;
      .el-checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      ul {
        display: flex;
        flex: 1;
        li {
          padding: $def-margin * 2 $def-margin * 3;
          color: #666;
          font-size: 14px;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          white-space: nowrap;
        }
        .goods-name-item {
          flex: 2.4;
        }
      }
    }

    .goods-query-list-main {
      // background-color: rgb(223, 125, 125);

      ul {
        li {
          height: 88px;
          display: flex;

          .el-checkbox {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .goods-query-list-main-content {
            display: flex;
            flex: 1;
            .goods-name-item {
              flex: 2.4;
              padding: $def-margin * 2 $def-margin * 3;
              display: flex;
              img {
                width: 88px - $def-margin * 2 * 2;
                height: 88px - $def-margin * 2 * 2;
                background-color: rgb(117, 60, 60);
              }
              span {
                display: flex;
                align-items: center;
                color: #666;
                font-size: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                white-space: nowrap;
              }
            }
            span {
              display: flex;
              align-items: center;
              padding: $def-margin * 2 $def-margin * 3;
              color: #666;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              white-space: nowrap;
              flex: 1;
            }
          }
        }
      }
    }
  }
}
</style>
